import React from 'react'
import { StaticImage } from 'gatsby-plugin-image'
import { CurvyDividerTop } from './dividers'

function Footer() {
  return (
    <footer className="max-w-[2560px] mx-auto relative pt-32 lg:pt-72 pb-20">
      <CurvyDividerTop />
      <div className="max-w-screen-xl mx-auto px-3 lg:px-0">
        <StaticImage
          src="../../static/img/ASBANSW_footer.webp"
          alt="ASBANSW Footer Logo"
          placeholder="blurred"
          layout="constrained"
          formats={["auto", "webp", "avif"]}
        />
      </div>
    </footer>
  )
}

export default Footer
